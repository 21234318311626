import Vue from 'vue';
import moment from 'moment';
import App from './App.vue';
import router from './router';
import store from './store';

import './plugins/antdv';
import 'ant-design-vue/dist/antd.css';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
