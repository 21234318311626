import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accountInfo: '',
  },
  mutations: {
    setAccountInfo(state, data) {
      state.accountInfo = data;
      localStorage.accountInfo = data;
    },
  },
  getters: {
    accountInfo: (state) => {
      if (state.accountInfo) {
        return state.accountInfo;
      }

      return localStorage.accountInfo || '尊贵用户';
    },
  },
  actions: {
  },
  modules: {
  },
});
