import Vue from 'vue';
import {
  Pagination,
  Button,
  LocaleProvider,
  Layout,
  Row,
  Col,
  Input,
  Menu,
  Icon,
  Table,
  message,
  Form,
  FormModel,
  Radio,
  Modal,
  Tag,
  Steps,
  Select,
  Spin,
  Breadcrumb,
  Upload,
  Divider,
  Checkbox,
  Slider,
  Alert,
  InputNumber,
  Tabs,
  DatePicker,
  PageHeader,
} from 'ant-design-vue';

Vue.component(LocaleProvider.name, LocaleProvider);
Vue.component(Pagination.name, Pagination);
Vue.component(Button.name, Button);
Vue.use(DatePicker);
Vue.use(PageHeader);
Vue.use(Layout);
Vue.use(Menu);
Vue.use(Row);
Vue.use(Col);
Vue.use(Input);
Vue.use(Table);
Vue.use(Modal);
Vue.use(Tag);
Vue.prototype.$message = message;
Vue.use(Form);
Vue.use(Radio);
Vue.use(FormModel);
Vue.use(Steps);
Vue.use(Spin);
Vue.use(Select);
Vue.use(Breadcrumb);
Vue.use(Upload);
Vue.use(Divider);
Vue.use(Checkbox);
Vue.use(Slider);
Vue.use(Alert);
Vue.use(Tabs);
Vue.use(InputNumber);

Vue.component(Icon.name, Icon);
